import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/navigation';
import classNames from 'classnames';
import { useTranslations } from 'next-intl';
import useFade from '../../hooks/useFade';
import urls from '../../utils/urls';
import Typography from '../Typography';
import Button from '../Button';
import PinIcon from '../Svg/PinIcon';
import ChevronRight from '../Svg/ChevronRight';

const HeaderLocation = ({
  location,
}) => {
  const dropdownWrapper = useRef(null);
  const [isDropdownVisible, setDropdownVisible, visibleProps] = useFade(false);

  // router
  const router = useRouter();

  // Header translations
  const t = useTranslations('Header');

  // Set dropdown open or closed
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Link helper
  const onLinkClick = (url) => {
    return () => {
      setDropdownVisible(false);
      setDropdownOpen(false);
      router.push(url);
    };
  };

  // Show/Hide Store location dropdown on click
  const handleDropdownClick = () => {
    setDropdownVisible(!isDropdownVisible);
    return setDropdownOpen(!dropdownOpen);
  };

  // close dropdown menu if you click outside the menu
  useEffect(() => {
    const determineClick = (e) => {
      e.stopPropagation();

      // determine if click is inside the wrapper or button
      const dropdownEl = dropdownWrapper?.current?.contains(e.target);
      const dropdownButtonEl = e.target.classList.contains('dropdown-button');

      // if the click is outside the wrapper, close dropdown menu
      if (!dropdownEl && !dropdownButtonEl) {
        setDropdownVisible(false);
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', determineClick);

    return () => {
      document.removeEventListener('mousedown', determineClick);
    };
  }, [setDropdownOpen, setDropdownVisible]);

  const headerLocation = (
    <div className={classNames('header-location-container', { open: dropdownOpen })} key="header-location-container">
      {location && !!Object.keys(location).length && (
        <>
          <Typography variant="small" addClasses="dropdown-label">{t('location.text')}</Typography>
          <Button
            aria-expanded={dropdownOpen}
            label={location.name}
            variant="secondary"
            theme="light"
            addClasses="dropdown-button"
            click={handleDropdownClick}
            Icon={PinIcon}
            Icon2={ChevronRight}
          />
        </>
      )}
    </div>
  );

  const dropdown = (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <React.Fragment key="header-location-dropdown">
      {isDropdownVisible && (
        <div
          className="header-location-dropdown"
          key="location-dropdown"
          onAnimationEnd={visibleProps.onAnimationEnd}
          ref={dropdownWrapper}
          style={visibleProps.style}
        >
          <div className="location-dropdown-wrapper">
            <div className="location-dropdown-container">
              <div className="location-info">
                {location && !!Object.keys(location).length && (
                  <address className="sm-space">
                    {location.name && (
                      <button className="location-name" onClick={onLinkClick(`${urls.location}${location.slug}`)} type="button">
                        <Typography variant="heading-400">{location.name}</Typography>
                        <ChevronRight />
                      </button>
                    )}
                    {location.address && (
                      <Typography variant="small" addClasses="location-address">{location.address}</Typography>
                    )}
                    {location.city && location.state && location.zip && (
                      <Typography variant="small" addClasses="location-zip">{`${location.city} ${location.state} ${location.zip}`}</Typography>
                    )}
                    {location.phone && (
                      <a href={`tel:${location.phone.replace(/\W/g, '')}`}><Typography variant="small" addClasses="location-phone">{location.phone}</Typography></a>
                    )}
                  </address>
                )}
              </div>
              <Button
                click={onLinkClick(`${urls.storeLocator}`)}
                label={t('location.button.label')}
                variant="secondary"
                theme="dark"
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );

  return (
    <div className="header-location-wrapper" aria-label={t('location.ariaLabel')} id="header-location" tabIndex="-1">
      {[headerLocation, dropdown]}
    </div>
  );
};

HeaderLocation.propTypes = {
  location: PropTypes.object.isRequired,
};

export default HeaderLocation;
