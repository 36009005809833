import React from 'react';
import PropTypes from 'prop-types';

const Hamburger = ({ height = 24, width = 24 }) => {
  return (
    <div
      style={{
        width,
        height,
      }}
      className="svg"
    >
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ margin: '0 0 0 -2px' }}>
        <path d="M3 4.673h18v2H3v-2zm0 7h12v2H3v-2zm0 7h18v2H3v-2z" fill="#3F385A" />
      </svg>
    </div>
  );
};

Hamburger.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Hamburger;
