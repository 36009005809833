import { useState, useEffect } from 'react';

const useFade = (visible) => {
  const [show, setShow] = useState(visible);
  const [isVisible, setVisible] = useState(show);

  useEffect(() => {
    if (show) setVisible(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setVisible(false);
  };

  const style = {
    animation: `${show ? 'fadeInUp' : 'fadeOutDown'} 0.2s cubic-bezier(0, 0, 0.2, 1)`,
    opacity: `${show ? 1 : 0}`,
  };

  const props = { onAnimationEnd, style };

  return [isVisible, setShow, props];
};

export default useFade;
