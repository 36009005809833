import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useTranslations } from 'next-intl';
import CloseButtonModal from '../CloseButtonModal';
import Typography from '../Typography';
import Button from '../Button';

const SessionTimeoutModal = ({
  countdown,
  isOpen,
  onClose,
  siteData,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  // translations
  const t = useTranslations('KioskMode.sessionTimeout');

  // countdown, millisecs to secs
  const [countdownNum, setCountdownNum] = useState(countdown / 1000);
  const countdownIntervalRef = useRef(null);

  useEffect(() => {
    // reset state after the modal closes
    // so we don't see it change to the
    // initial value before it closees
    if (!isOpen) {
      setTimeout(() => {
        setCountdownNum(countdown / 1000);
      }, 2000); // 2 secs
    }

    if (countdownNum > 0 && isOpen) {
      countdownIntervalRef.current = setInterval(() => {
        setCountdownNum((prevCountdownNum) => {
          return prevCountdownNum - 1;
        });
      }, 1000); // 1 sec
    }

    return () => {
      clearInterval(countdownIntervalRef.current);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dialog
      classes={{ paper: 'modal sessiontimeout-modal' }}
      fullScreen={!matches}
      fullWidth
      maxWidth="lg"
      onClose={onClose}
      open={isOpen}
    >
      <CloseButtonModal
        onClose={onClose}
        dataContentName="session timeout modal"
        dataContentPiece="exit"
      />
      <div className="modal-top-border" style={{ backgroundColor: siteData?.primaryColor }} />
      <DialogContent tabIndex={-1} className="sessiontimeout-content">
        <div className="text-color-wrapper">
          <Typography variant="heading-600">{t('copy.headline')}</Typography>
          <Typography variant="heading-700" addClasses="countdown-num">{t('copy.subhead', { countdownNum })}</Typography>
          <Typography variant="body">{t('copy.body')}</Typography>
          <Button label={t('ctas.yes.label')} variant="secondary" theme="dark" addClasses="sessiontimeout-cta" click={onClose} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

SessionTimeoutModal.propTypes = {
  countdown: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  siteData: PropTypes.object.isRequired,
};

export default SessionTimeoutModal;
