'use client';

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { usePathname, useSearchParams } from 'next/navigation';

const RouteChangeListener = ({ fn }) => {
  const pathname = usePathname();
  const searchParams = useSearchParams().toString();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (typeof fn === 'function') {
      fn();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, searchParams]);

  return false;
};

RouteChangeListener.propTypes = {
  fn: PropTypes.func,
};

export default RouteChangeListener;
