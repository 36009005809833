import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from 'next-intl';
import CloseButtonModalSvg from '../Svg/CloseButtonModal';

const CloseButtonModal = ({ dataContentName, dataContentPiece, onClose }) => {
  // translations
  const t = useTranslations('Modals');

  return (
    <button
      aria-label={t('globalClose.btn.label')}
      className="modal-close-btn"
      data-content-name={dataContentName}
      data-content-piece={dataContentPiece}
      onClick={onClose}
      type="button"
    >
      <CloseButtonModalSvg />
    </button>
  );
};

CloseButtonModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  dataContentName: PropTypes.string.isRequired,
  dataContentPiece: PropTypes.string.isRequired,
};

export default CloseButtonModal;
