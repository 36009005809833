// vars and query params for pagination

const paginationConfig = {
  allProductsParam: 'all',
  allProductsSize: 999,
  autocompleteResultSize: 5,
  pageSize: 12,
};

export default paginationConfig;
