'use client';

import { useContext, useState, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { useTranslations } from 'next-intl';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import Typography from '../Typography';
import ChevronLeft from '../Svg/ChevronLeft';
import { kioskReset, setKioskMode } from '../../actions/kiosk-actions';
import { AppContext } from '../../context/AppContext';
import SessionTimeoutModal from '../SessionTimeoutModal';
import CloseButtonModal from '../Svg/CloseButtonModal';
import Button from '../Button';
import {
  addSessionModalEventListeners,
  removeSessionModalEventListeners,
} from '../../utils/sessionTimeout';

const KioskModeBar = ({ kioskModeQS, siteData }) => {
  const router = useRouter();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  // translations
  const t = useTranslations('KioskMode');

  const [kioskModeEnabled, setkioskModeEnabled] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  // open/close session timeout modal
  const [sessionTimeoutModalIsOpen, setSessionTimeoutModalIsOpen] = useState(false);

  // vars for session timeout modal
  const openTimeout = 5 * 60 * 1000; // 5 mins
  const closeTimeout = 30 * 1000; // 30 secs

  // context
  const {
    context,
    setContext,
    setImageContext,
  } = useContext(AppContext);

  // Click handler for the < Back button
  const backClick = () => {
    router.back();
  };

  // Click handler for the Reset button
  const resetClick = () => {
    setIsConfirmModalOpen(true);
  };

  // Click handler for the Yes button in the reset confirmation modal
  const resetConfirm = () => {
    setIsConfirmModalOpen(false);
    router.push('/');
    kioskReset(setContext, setImageContext);
  };

  // Update state with kioskMode value from context on change
  useEffect(() => {
    if (context?.kioskMode) {
      setkioskModeEnabled(true);
    }
  }, [context?.kioskMode]);

  // Enable kiosk mode (in context) if the query param is present
  useEffect(() => {
    if (kioskModeQS) {
      setKioskMode(setContext);
    }
  }, [kioskModeQS, setContext]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (kioskModeEnabled) {
      const openSessionModalTimeout = () => {
        return setTimeout(() => {
          setSessionTimeoutModalIsOpen(true);
        }, openTimeout);
      };

      const closeSessionModalTimeout = () => {
        return setTimeout(() => {
          setSessionTimeoutModalIsOpen(false);
          setIsConfirmModalOpen(false);
          router.push('/');
          kioskReset(setContext, setImageContext);
        }, closeTimeout);
      };

      let currentTimeout = sessionTimeoutModalIsOpen
        ? closeSessionModalTimeout()
        : openSessionModalTimeout();

      const listener = () => {
        if (!sessionTimeoutModalIsOpen) {
          clearTimeout(currentTimeout);
          currentTimeout = openSessionModalTimeout();
        }
      };

      addSessionModalEventListeners(listener);

      return () => {
        removeSessionModalEventListeners(listener);
        clearTimeout(currentTimeout);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeTimeout, kioskModeEnabled, openTimeout, sessionTimeoutModalIsOpen]);

  const closeSessionTimeoutModal = () => {
    setSessionTimeoutModalIsOpen(false);
  };

  const resetConfirmationModal = (
    <Dialog
      classes={{ paper: 'modal kiosk-confirm-reset-modal' }}
      fullScreen={!matches}
      fullWidth
      keepMounted
      onClose={() => { setIsConfirmModalOpen(false); }}
      open={isConfirmModalOpen}
    >
      <button
        aria-label="close modal"
        className="modal-close-btn"
        onClick={() => { setIsConfirmModalOpen(false); }}
        type="button"
        data-content-name="kiosk reset modal"
        data-content-piece="exit"
      >
        <CloseButtonModal />
      </button>
      <div className="modal-top-border" style={{ backgroundColor: siteData?.primaryColor }} />
      <DialogContent
        tabIndex={-1}
        className="kiosk-confirm-reset-modal-wrapper"
      >
        <Typography variant="heading-600" addClasses="headline">
          {t('ConfirmResetModal.headline')}
        </Typography>
        <Typography variant="body" addClasses="subhead">
          {t('ConfirmResetModal.subhead')}
        </Typography>

        <div className="ctas">
          <Button
            variant="secondary"
            theme="dark"
            label={t('ConfirmResetModal.ctas.yes.label')}
            click={resetConfirm}
            trackingDetails={{
              dataContentName: 'kiosk reset modal',
              dataContentPiece: 'yes button',
            }}
          />
          <Button
            variant="secondary"
            theme="light"
            label={t('ConfirmResetModal.ctas.cancel.label')}
            click={() => { setIsConfirmModalOpen(false); }}
            trackingDetails={{
              dataContentName: 'kiosk reset modal',
              dataContentPiece: 'cancel button',
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );

  return kioskModeEnabled ? (
    <>
      <div className="kiosk-mode-bar">
        <div className="wrapper kiosk-bar-content">
          <button
            type="button"
            onClick={backClick}
            className="back-button"
          >
            <ChevronLeft />
            <Typography variant="body">{t('ctas.back.label')}</Typography>
          </button>
          <button
            type="button"
            onClick={resetClick}
          >
            <Typography variant="body">{t('ctas.reset.label')}</Typography>
          </button>
        </div>
      </div>
      <SessionTimeoutModal
        countdown={closeTimeout}
        isOpen={sessionTimeoutModalIsOpen}
        onClose={closeSessionTimeoutModal}
        siteData={siteData}
      />
      {resetConfirmationModal}
    </>
  ) : undefined;
};

export default KioskModeBar;
