/* eslint-disable import/prefer-default-export */
const events = [
  'keydown',
  'mousedown',
  'mousemove',
  'pointermove',
  'scroll',
  'touchmove',
  'wheel',
];

export const addSessionModalEventListeners = (listener) => {
  events.forEach((event) => {
    window.addEventListener(event, listener, false);
  });
};

export const removeSessionModalEventListeners = (listener) => {
  if (listener) {
    events.forEach((event) => {
      window.removeEventListener(event, listener, false);
    });
  }
};
