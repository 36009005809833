import types from './types';

// Resets the context to the initial state (with kiosk mode still enabled)
export const kioskReset = (setContext, setImageContext) => {
  setContext({
    type: types.KIOSK_RESET,
  });
  setImageContext({
    type: types.RESET_CAKE_CUSTOMIZE_IMAGES,
  });
};

// Enables kiosk mode
export const setKioskMode = (setContext) => {
  setContext({
    type: types.SET_KIOSK_MODE,
  });
};
