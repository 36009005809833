'use client';

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams, usePathname, useRouter, useSearchParams } from 'next/navigation';
import Link from 'next/link';
import classnames from 'classnames';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import getGeolocation from '../../utils/getGeolocation';
import SearchInput from '../SearchInput';
import Hamburger from '../Svg/Hamburger';
import HeaderLocation from '../HeaderLocation';
import NavMenuMobile from '../NavMenuMobile';
import ConfirmUpdateLocationModal from '../ConfirmUpdateLocationModal';
import { AppContext } from '../../context/AppContext';
import urls from '../../utils/urls';
import {
  resetCakeCustomize,
  resetCakeCustomizeImages,
  clearOrderData,
  setConfirmUpdateLocationModalOpen,
  setNewLocation,
} from '../../actions/customize-actions';

const HeaderApp = ({
  categories,
  locale,
  locationId,
  siteData,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [kioskModeEnabled, setkioskModeEnabled] = useState(false);
  const params = useParams();
  const pathname = usePathname();
  const router = useRouter();
  const searchParams = useSearchParams();
  const { category: currentCategory } = params;

  // translations
  const t = useTranslations('Header');

  // translations for geolocation
  const t2 = useTranslations('Locations');

  // context
  const {
    context,
    setContext,
    setImageContext,
  } = useContext(AppContext);

  // Handler for clicking Yes in the confirm update location modal
  const yesClick = () => {
    // reset customziations and order data
    clearOrderData(setContext);
    resetCakeCustomize(setContext);
    resetCakeCustomizeImages(setImageContext);

    // close the modal
    setConfirmUpdateLocationModalOpen(setContext, false);

    // clear the pending store change in context since we just updated location
    setNewLocation(setContext, null);

    // update location via the pending store in context by redirecting
    if (context?.newLocation) {
      const href = `/?location=${context.newLocation}`;
      window.location.assign(href);
    }
  };

  // Handler for closing the confirm update location modal
  const closeConfirmModal = () => {
    // clear the pending location change
    setNewLocation(setContext, null);

    // close the modal
    setConfirmUpdateLocationModalOpen(setContext, false);
  };

  // Update state with kioskMode value from context on change
  useEffect(() => {
    if (context?.kioskMode) {
      setkioskModeEnabled(true);
    }
  }, [context?.kioskMode]);

  // Open the update location confirmation modal based on context
  useEffect(() => {
    if (context?.isConfirmUpdateLocationModalOpen) {
      setConfirmModalOpen(true);
    } else {
      setConfirmModalOpen(false);
    }
  }, [context?.isConfirmUpdateLocationModalOpen]);

  // Geolocate on Load - TODO: currently disabled until team is ready
  // This can be enabled by uncommenting the call to handleGeoStore below
  useEffect(() => {
    // Change store on Geolocation
    // eslint-disable-next-line no-unused-vars
    const handleGeoStore = async (data) => {
      let geoStoreList = [];

      // get store list from geolocation
      try {
        const url = `${process.env.NEXT_PUBLIC_WOLVERINE_URL}api/${siteData.siteKey}/locations/?geo=${data.coords.latitude},${data.coords.longitude}&radius=2000`;
        const resp = await fetch(url);
        geoStoreList = await resp.json();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('error getting store list from geolocation:', e);
      }

      // Redirect user to the closest store if no store saved to location Id
      // Conditional logic if team prefers to change store on any geo change:
      // (Number(locationId) !== geoStoreList[0]?.id)
      if (!locationId && geoStoreList?.length) {
        // probably don't need this logic after all, since there's no guarantee
        // that categories, subcategories, and products will be the same between
        // stores. this code was to preserve the URL, but really we should just
        // redirect to the home page instead.

        /*
         * EDIT: edited to redirect to home page, keep
         * searchParams, but set location to closest store
         */

        const urlSearchParams = new URLSearchParams(searchParams.toString());
        urlSearchParams.set('location', geoStoreList[0]?.id);
        const url = `/?${urlSearchParams.toString()}`;

        router.push(url);
      }
    };

    // Call the getGeolocation util
    const handleGeolocate = async () => {
      try {
        const result = await getGeolocation(t2);

        // eslint-disable-next-line no-console
        console.log('Geolocation success:', result);

        // !!TODO: Re-enable this when team has capacity to test
        // redirect thoroughly throughout site
        // handleGeoStore(result);
      } catch (e) {
        // Geolocation returned an error
        // eslint-disable-next-line no-console
        console.error('No geolocation returned:', e);
      }
    };

    handleGeolocate();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // don't render the header if we are at an excluded route
  // TODO: we should use nested laouts and route groups to render the header
  const excludeHeaderRoutes = [urls.checkout];
  const includeHeader = !excludeHeaderRoutes.includes(`${pathname}`);
  if (!includeHeader) return false;

  const hamburger = (
    <button
      aria-label={t('hamburger.ariaLabel')}
      className="header-hamburger"
      type="button"
      onClick={() => {
        return setMobileMenuOpen(true);
      }}
      data-content-name="header"
      data-content-piece="hamburger button"
      key="hamburger"
    >
      <Hamburger />
    </button>
  );

  const logo = (logoUrl) => {
    return (
      <Link
        data-content-name="header"
        data-content-piece="logo button"
        href="/"
        key="logo"
        prefetch={false}
        tabIndex="0"
        aria-label={siteData?.brandName}
        className="header-logo-container"
      >
        <Image
          alt={siteData?.brandName || ''}
          className="header-logo-image"
          fill
          priority="true"
          src={logoUrl}
          data-content-name="header"
          data-content-piece="logo button"
        />
      </Link>
    );
  };

  const desktopSearchForm = (
    <SearchInput
      ariaLabel={t('search.input.ariaLabel')}
      ariaButtonLabel={t('search.btns.submit.ariaLabel')}
      ariaButtonLabelClear={t('search.btns.clear.ariaLabel')}
      ariaButtonLabelClose={t('search.btns.close.ariaLabel')}
      autoCompleteCtaLabel={t('search.autoComplete.cta.label')}
      id="search-desktop"
      key="search-input"
      locale={locale}
      locationId={locationId}
      name="q"
      placeHolder={t('search.input.placeholder')}
      type="desktop"
    />
  );

  const mobileSearchForm = (
    <SearchInput
      ariaLabel={t('search.input.ariaLabel')}
      ariaButtonLabel={t('search.btns.submit.ariaLabel')}
      ariaButtonLabelClear={t('search.btns.clear.ariaLabel')}
      ariaButtonLabelClose={t('search.btns.close.ariaLabel')}
      autoCompleteCtaLabel={t('search.autoComplete.cta.label')}
      id="search-mobile"
      key="search-input"
      locale={locale}
      locationId={locationId}
      name="q"
      placeHolder={t('search.input.placeholder')}
      type="mobile"
    />
  );

  const headerLocation = (
    <HeaderLocation
      key="header-location"
      locationId={locationId}
      location={siteData?.location}
      siteKey={siteData?.siteKey}
    />
  );

  const navMenuMobile = (
    <NavMenuMobile
      categories={categories}
      isOpen={mobileMenuOpen}
      key="nav-menu-mobile"
      locationId={locationId}
      location={siteData?.location}
      onClose={() => { setMobileMenuOpen(false); }}
      siteData={siteData}
    />
  );

  const mobile = (
    <header className="header header-sm" key="mobile-header">
      <div className="header-top add-well">
        <div className="header-hamburger">{hamburger}</div>
        {(siteData?.mobileLogo || siteData?.logo) && (
          <div className="header-logo">
            {logo(siteData.mobileLogo ? siteData.mobileLogo : siteData.logo)}
          </div>
        )}
        <div className="header-search-input">{mobileSearchForm}</div>
      </div>
      <div className="nav-menu-mobile">
        {navMenuMobile}
      </div>
    </header>
  );

  const desktop = (
    <header className="header header-lg" key="desktop-header">
      <div className="header-top add-well">
        {siteData?.logo && logo(siteData.logo)}
        {/* hide the location button/pop up in kiosk mode */}
        {!kioskModeEnabled && locationId && (
          <div className="header-location">
            {headerLocation}
          </div>
        )}
        <div className="header-search-input">{desktopSearchForm}</div>
      </div>
      <nav className="header-nav">
        <ul className="header-nav-list add-well">
          {categories?.map((category) => {
            return (
              <li key={category.slug}>
                <Link
                  href={`/${category.slug}/`}
                  prefetch={false}
                  className={classnames('header-nav-anchor', { 'header-nav-anchor-selected': currentCategory === category.slug })}
                  data-content-name="header-nav"
                  data-content-piece={category.name}
                >
                  {category.name}
                  <span className="header-nav-highlight" />
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </header>
  );

  return (
    <div className="header-container" role="navigation" aria-label={t('nav.ariaLabel')} id="header" tabIndex="-1">

      {[desktop, mobile]}

      {/* don't need to load this in kiosk mode since they can't change their location */}
      {!kioskModeEnabled && (
        <ConfirmUpdateLocationModal
          isOpen={confirmModalOpen}
          closeModal={closeConfirmModal}
          siteData={siteData}
          yesClick={yesClick}
        />
      )}
    </div>
  );
};

HeaderApp.propTypes = {
  categories: PropTypes.array,
  locale: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  siteData: PropTypes.object.isRequired,
};

export default HeaderApp;
