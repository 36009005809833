/* eslint-disable consistent-return */
import { setCookie } from 'cookies-next';

const setLocale = (locale) => {
  const days = 7;

  // 1 day in seconds * days
  const maxAge = 60 * 60 * 24 * days;

  // If locale is passed, overwrite
  return setCookie('NEXT_LOCALE', locale, { maxAge });
};

export default setLocale;
