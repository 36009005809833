import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { usePathname, useRouter } from 'next/navigation';
import Drawer from '@mui/material/Drawer';
import { useTranslations } from 'next-intl';
import urls from '../../utils/urls';
import Typography from '../Typography';
import CloseButton from '../Svg/CloseButton';
import ChevronLeft from '../Svg/ChevronLeft';
import ChevronRight from '../Svg/ChevronRight';
import { AppContext } from '../../context/AppContext';

const NavMenuMobile = ({
  categories = [],
  isOpen = false,
  location,
  onClose = null,
  siteData,
}) => {
  const logo = siteData?.mobileLogo || siteData?.logo;

  const [kioskModeEnabled, setkioskModeEnabled] = useState(false);

  // context
  const {
    context,
  } = useContext(AppContext);

  // translations
  const t = useTranslations('Header');

  // get router
  const router = useRouter();

  // get pathname
  const pathname = usePathname();

  // Current Menu to Display
  const [currentMenu, setCurrentMenu] = useState(null);

  // Current Menu Title
  const [menuTitle, setMenuTitle] = useState(t('mobileMenu.menu'));

  const onLinkClick = (url) => {
    return () => {
      setCurrentMenu(null);
      setMenuTitle(t('mobileMenu.menu'));
      router.push(url);
      onClose();
    };
  };

  const onCloseClick = () => {
    // Close mobile nav
    onClose();

    // Reset menu
    setCurrentMenu(null);
    setMenuTitle(t('mobileMenu.menu'));
  };

  // Update state with kioskMode value from context on change
  useEffect(() => {
    if (context?.kioskMode) {
      setkioskModeEnabled(true);
    }
  }, [context?.kioskMode]);

  const menuCategories = (
    <div className="menu-categories" key="categories">
      <ul>
        {categories && categories.map((category) => {
          const selectedStyle = (pathname === `/${category.slug}/`) ? {
            border: `2px solid ${siteData.primaryColor}`,
          } : null;
          return (
            <li
              key={category.slug}
              className="mobile-nav-category-item"
              style={selectedStyle}
            >
              <button
                className="mobile-nav-category-link"
                onClick={onLinkClick(`/${category.slug}/`)}
                type="button"
              >
                <Typography variant="heading-600">{category.name}</Typography>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );

  const menuStoreInfo = (
    <div className="menu-store-info" key="store-info">
      <div className="location-info-mobile">
        {location && !!Object.keys(location).length && (
          <address className="sm-space">
            {location.name && (
              <button className="location-title" onClick={onLinkClick(`${urls.location}${location.slug}`)} type="button">
                <Typography variant="heading-400" addClasses="location-name">
                  {location.name}
                </Typography>
                <ChevronRight />
              </button>
            )}
            {location.address && (
              <Typography variant="small">{location.address}</Typography>
            )}
            {location.city && location.state && location.zip && (
              <Typography variant="small">{`${location.city} ${location.state} ${location.zip}`}</Typography>
            )}
            {location.phone && (
              <a href={`tel:${location.phone.replace(/\W/g, '')}`}><Typography variant="small">{location.phone}</Typography></a>
            )}
          </address>
        )}
      </div>
      <button
        type="button"
        className="find-more-stores"
        onClick={onLinkClick(`${urls.storeLocator}`)}
      >
        <Typography variant="heading-600">{t('location.button.label')}</Typography>
      </button>
    </div>
  );

  const menuTopLevel = (
    <div className="menu-top-level" key="top-level">
      <ul>
        {categories && (
          <li
            key="categories"
            className="mobile-nav-category-item mobile-nav-top-level-item"
          >
            <button
              className="mobile-nav-category-link mobile-nav-top-level-link"
              onClick={() => {
                setCurrentMenu('categories');
                setMenuTitle(t('mobileMenu.categories'));
              }}
              type="button"
            >
              <Typography variant="heading-600">{t('mobileMenu.categories')}</Typography>
            </button>
          </li>
        )}

        {/* Store Information. don't show in kiosk mode */}
        {!kioskModeEnabled && (
        <li
          key="store-information"
          className="mobile-nav-category-item mobile-nav-top-level-item"
        >
          <button
            className="mobile-nav-category-link mobile-nav-top-level-link"
            onClick={() => {
              setCurrentMenu('storeInfo');
              setMenuTitle(t('mobileMenu.storeInfo'));
            }}
            type="button"
          >
            <Typography variant="heading-600">{t('location.button.label')}</Typography>
          </button>
        </li>
        )}

      </ul>
    </div>
  );

  const getMenu = () => {
    switch (currentMenu) {
      case 'categories':
        return (menuCategories);
      case 'storeInfo':
        return (menuStoreInfo);
      default:
        return (menuTopLevel);
    }
  };

  return (
    <Drawer
      anchor="left"
      classes={{ paper: 'mobile-nav-menu' }}
      onClose={onCloseClick}
      open={isOpen}
      variant="temporary"
    >
      <div className="mobile-nav-menu-title" style={{ backgroundColor: siteData?.primaryColor }}>
        <button
          type="button"
          className="mobile-nav-close-button"
          onClick={onCloseClick}
          data-content-name="mobile menu"
          data-content-piece="close"
          aria-label={t('ctas.close.ariaLabel')}
        >
          <CloseButton width={48} height={48} />
        </button>
        <button
          type="button"
          className="button-title"
          onClick={() => {
            setCurrentMenu('topLevel');
            setMenuTitle(t('mobileMenu.menu'));
          }}
        >
          {(currentMenu === 'storeInfo' || currentMenu === 'categories') && <ChevronLeft />}
          <Typography variant="heading-700">{menuTitle}</Typography>
        </button>
      </div>
      <div className="mobile-nav-menu-content">
        {getMenu()}
        {logo && (
          <div className="mobile-nav-logo">
            <button
              aria-label={t('logo.alt')}
              className="mobile-nav-logo-image"
              onClick={onLinkClick('/')}
              style={{ backgroundImage: `url(${logo})` }}
              type="button"
            />
          </div>
        )}
      </div>
    </Drawer>
  );
};

NavMenuMobile.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  siteData: PropTypes.object.isRequired,
};

export default NavMenuMobile;
