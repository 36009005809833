'use client';

// disable unused vars rule so we can keep the language selector functionality
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useTranslations } from 'next-intl';
import classNames from 'classnames';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import setLocale from '../../utils/setLocale';
import Button from '../Button/Button';
import FooterModal from '../FooterModal';
import GlobeIcon from '../Svg/GlobeIcon';
import ChevronRight from '../Svg/ChevronRight';
import urls from '../../utils/urls';

const Footer = ({ locale, siteData }) => {
  // get the router, pathname, and query
  const router = useRouter();
  const pathname = usePathname();
  const query = useSearchParams().toString();

  // init language values
  const languageOptions = [
    { language: 'English', path: 'en' },
    { language: 'Español', path: 'es' },
    { language: 'Français', path: 'fr' },
  ];

  const [openModal, setOpenModal] = useState({ variant: '', isOpen: false });
  const [languageAnchor, setLanguageAnchor] = useState(null);

  const currYear = new Date().getFullYear();

  const desktopLogo = siteData?.logo;
  const mobileLogo = siteData?.mobileLogo ? siteData?.mobileLogo : siteData?.logo;

  // translations
  const t = useTranslations('Footer');
  const t2 = useTranslations('LanguageSelector');

  // don't render the footer if we are at an excluded route
  // TODO: we should use nested laouts and route groups to render the footer
  const excludeFooterRoutes = [urls.checkout, urls.configurator];
  const includeFooter = !excludeFooterRoutes.includes(`${pathname}`);
  if (!includeFooter) return false;

  // Language dropdown setup
  const languageMenuIsOpen = Boolean(languageAnchor);

  // Language menu handlers
  const handleLanguageClick = (e) => {
    setLanguageAnchor(e.currentTarget);
  };

  const handleLanguageItemClick = (e, languageOption) => {
    setLanguageAnchor(null);
    setLocale(languageOption);

    // Route to locale while maintaining existing path
    if (locale !== languageOption) {
      router.push(`${pathname}?${query}`);
    }
  };

  const handleLanguageClose = () => {
    setLanguageAnchor(null);
  };

  return (
    <div className="footer-container">
      <footer className="footer add-well">
        <div className="footer-logo">
          <Link
            href="/"
            prefetch={false}
            aria-label={siteData?.brandName}
          >
            {mobileLogo && (
              <span
                className="footer-logo-image mobile"
                style={{ backgroundImage: `url(${mobileLogo})` }}
                data-content-name="footer"
                data-content-piece="logo button mobile"
              />
            )}
            {desktopLogo && (
              <span
                className="footer-logo-image desktop"
                style={{ backgroundImage: `url(${desktopLogo})` }}
                data-content-name="footer"
                data-content-piece="logo button"
              />
            )}
          </Link>
        </div>
        <div className="footer-bottom">
          <ul className="footer-nav-list">
            <li className="footer-nav-item">
              <Button
                addClasses="color-n1-400 footer-nav-link"
                click={() => { return setOpenModal({ variant: 'privacy', isOpen: true }); }}
                label={t('ctas.privacyPolicy.label')}
                trackingDetails={{ contentName: 'footer', contentPiece: 'privacy button' }}
                variant="text-link"
              />
            </li>
            <li className="footer-nav-item">
              <Button
                addClasses="color-n1-400 footer-nav-link"
                click={() => { return setOpenModal({ variant: 'terms', isOpen: true }); }}
                label={t('ctas.termsOfUse.label')}
                trackingDetails={{ contentName: 'footer', contentPiece: 'terms of use button' }}
                variant="text-link"
              />
            </li>
            <li className="footer-nav-item">
              <Button
                addClasses="color-n1-400 footer-nav-link"
                click={() => { return setOpenModal({ variant: 'info', isOpen: true }); }}
                label={t('ctas.myInformation.label')}
                trackingDetails={{ contentName: 'footer', contentPiece: 'do not sell button' }}
                variant="text-link"
              />
            </li>
          </ul>
          <ul className="footer-nav-list-secondary">
            <li className="footer-nav-item">
              <Button
                addClasses="color-n1-400 footer-nav-link"
                click={() => { return setOpenModal({ variant: 'health', isOpen: true }); }}
                label={t('ctas.myHealthMyData.label')}
                trackingDetails={{ contentName: 'footer', contentPiece: 'my health my data button' }}
                variant="text-link"
              />
            </li>
            <li className="footer-nav-item">
              {/* TODO: Include 'Cookie settings' label in translations */}
              <Button
                addClasses="color-n1-400 footer-nav-link ot-sdk-show-settings"
                label="Cookie Settings"
                variant="text-link"
              />
            </li>
            {/* <li className="footer-nav-item">
              <Button
                id="language-button"
                addClasses={classNames('color-n1-400', 'footer-nav-language', {
                  open: languageMenuIsOpen,
                })}
                aria-controls={languageMenuIsOpen ? 'language-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={languageMenuIsOpen ? 'true' : undefined}
                click={(e) => { return handleLanguageClick(e); }}
                Icon={GlobeIcon}
                Icon2={ChevronRight}
                label={t2('placeholder')}
                variant="secondary"
              />
              <Menu
                anchorEl={languageAnchor}
                classes={{ paper: 'footer-language-menu' }}
                id="language-menu"
                onClose={handleLanguageClose}
                open={languageMenuIsOpen}
                MenuListProps={{
                  'aria-labelledby': 'language-button',
                }}
              >
                {languageOptions.map((option) => {
                  return (
                    <MenuItem
                      key={option.language}
                      selected={locale === option.path}
                      onClick={(e) => { handleLanguageItemClick(e, option.path); }}
                    >
                      {option.language}
                      <ChevronRight />
                    </MenuItem>
                  );
                })}
              </Menu>
            </li> */}
          </ul>
          <div className="footer-fineprint">
            {`© ${currYear} Cakes.com ${t('finePrint')}`}
            <br />
            <div className="footer-fineprint-link">
              <Button
                click={() => { return setOpenModal({ variant: 'ip', isOpen: true }); }}
                label={t('ctas.intellectualProperty.label')}
                trackingDetails={{ contentName: 'footer', contentPiece: 'intellectual properties button' }}
                variant="text-link"
              />
            </div>
          </div>
        </div>
      </footer>
      <FooterModal
        isOpen={openModal?.isOpen}
        locale={locale}
        onClose={() => { return setOpenModal({ variant: '', isOpen: false }); }}
        siteData={siteData}
        variant={openModal?.variant}
      />
    </div>
  );
};

Footer.propTypes = {
  locale: PropTypes.string.isRequired,
  siteData: PropTypes.object.isRequired,
};

export default Footer;
