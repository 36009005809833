'use client';

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 375,
      md: 768,
      lg: 900,
      xl: 1440,
    },
  },
  palette: {
    primary: { main: '#333040' },
    background: {
      default: '#FFFFFF',
    },
  },
  overrides: {
    MuiCheckbox: {
      root: {
        color: '#D6D4DE',
      },
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export default theme;
