import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useTranslations } from 'next-intl';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import CloseButtonModal from '../CloseButtonModal';
import Typography from '../Typography';
import Button from '../Button';

const ConfirmUpdateLocationModal = ({
  isOpen,
  closeModal,
  siteData,
  yesClick,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const t = useTranslations('Modals.confirmUpdateLocation');

  return (
    <Dialog
      open={isOpen}
      classes={{ paper: 'confirm-update-location-modal' }}
      fullScreen={!matches}
      keepMounted
      maxWidth="md"
    >
      <CloseButtonModal
        onClose={closeModal}
        dataContentName="confirm update location modal"
        dataContentPiece="exit"
      />
      <div className="modal-top-border" style={{ backgroundColor: siteData?.primaryColor }} />
      <DialogContent>
        <Typography variant="heading-600" addClasses="heading">
          {t('heading')}
        </Typography>
        <Typography variant="body" addClasses="body">
          {t('body')}
        </Typography>

        <Button
          variant="secondary"
          theme="dark"
          label={t('ctas.yes.label')}
          addClasses="yes-button"
          click={yesClick}
        />

        <Button
          variant="secondary"
          theme="light"
          label={t('ctas.no.label')}
          addClasses="no-button"
          click={closeModal}
        />
      </DialogContent>
    </Dialog>
  );
};

ConfirmUpdateLocationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  siteData: PropTypes.object.isRequired,
  yesClick: PropTypes.func.isRequired,
};

export default ConfirmUpdateLocationModal;
