import React from 'react';

const CloseButtonModal = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
      <g>
        <path fill="currentColor" d="m16 13.98 5.657-5.656 1.886 1.885-5.657 5.657 5.657 5.657-1.886 1.886L16 17.752l-5.657 5.657-1.885-1.886 5.656-5.657-5.656-5.657 1.885-1.885L16 13.98z" />
      </g>
      <defs>
        <clipPath>
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseButtonModal;
