import React from 'react';
import PropTypes from 'prop-types';

const CloseButton = ({ height = 24, width = 24 }) => {
  return (
    <div style={{
      width,
      height,
    }}
    >
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 48">
        <path d="M.6521 24c0-13.2548 10.7452-24 24-24s24 10.7452 24 24-10.7452 24-24 24-24-10.7452-24-24z" fill="#C4E2D9" />
        <path d="M24.6521 21.5858l4.2426-4.2426 1.4143 1.4142L26.0663 23l4.2427 4.2426-1.4143 1.4143-4.2426-4.2427-4.2426 4.2427-1.4143-1.4143L23.2379 23l-4.2427-4.2426 1.4143-1.4142 4.2426 4.2426z" fill="#3F385A" />
      </svg>
    </div>
  );
};

CloseButton.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default CloseButton;
