/* eslint-disable react/no-danger, react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter, usePathname, useSearchParams } from 'next/navigation';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Backdrop from '@mui/material/Backdrop';
import classnames from 'classnames';
import Image from 'next/image';
import Search from '../Svg/Search';
import urls from '../../utils/urls';
import Typography from '../Typography';
import RouteChangeListener from '../RouteChangeListener';
import paginationConfig from '../../utils/pagination';

const SearchInput = ({
  ariaLabel,
  ariaButtonLabel,
  ariaButtonLabelClear,
  ariaButtonLabelClose,
  autoCompleteCtaLabel,
  disabled = false,
  fullWidth = false,
  hasButtonBg = true,
  id,
  locale,
  locationId,
  name = 'search',
  placeHolder = '',
  required = true,
  type,
}) => {
  const [autoCompleteResults, setAutoCompleteResults] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false);
  const [isKeydown, setIsKeydown] = useState(true);
  const desktopInputEl = useRef(null);
  const mobileInputEl = useRef(null);
  const desktopWrapper = useRef(null);
  const mobileWrapper = useRef(null);

  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams().toString();

  // NOTE: these two useEffects need to be in this order
  // or the router event listener will not work correctly

  // need to close the dropdown when a route changes
  const closeAutoCompleteResults = useCallback(() => {
    if (autoCompleteOpen) setAutoCompleteOpen(false);
    if (mobileSearchOpen) setMobileSearchOpen(false);
  }, [pathname, searchParams]);

  // Clear the search input if we navigate away from search page
  useEffect(() => {
    if (`${pathname}/` !== urls?.search) {
      setSearchValue('');
    }
  }, [pathname]);

  useEffect(() => {
    const determineClick = (e) => {
      e.stopPropagation();

      // determine if click is inside a wrapping element for desktop or mobile
      const desktopSearch = desktopWrapper?.current?.contains(e.target);
      const mobileSearch = mobileWrapper?.current?.contains(e.target);

      // if the click is outside the wrapper, close autocomplete
      if (autoCompleteOpen && !desktopSearch && !mobileSearch) {
        setAutoCompleteOpen(false);
      }
    };

    document.addEventListener('mousedown', determineClick);

    return () => {
      document.removeEventListener('mousedown', determineClick);
    };
  }, [autoCompleteOpen]);

  // Accessibility fix for Input field
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 9) { // Tab key
        setIsKeydown(true);
      }
    };

    const handleMouseDown = () => {
      setIsKeydown(false);
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('pointerdown', handleMouseDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('pointerdown', handleMouseDown);
    };
  }, []);

  // Mobile search
  const handleOpenMobileSearch = () => {
    setMobileSearchOpen(true);

    mobileInputEl.current.focus();
  };

  // close button on mobile search
  const handleCloseMobileSearch = (e) => {
    if (e.target.name === name) return;
    if (autoCompleteOpen) setAutoCompleteOpen(false);
    setMobileSearchOpen(false);
  };

  // get auto complete results
  const handleAutoComplete = async (e) => {
    const inputEl = e.target;
    setSearchValue(e.target.value);

    // matches empty string and whitespace
    if (!inputEl.value.match('^\\s*$') && inputEl.value?.length > 0) {
      const val = encodeURIComponent(inputEl.value.trim());

      const url = `${process.env.NEXT_PUBLIC_WOLVERINE_URL}api/${locationId}/search/?q=${val}&page=1&per_page=${paginationConfig.autocompleteResultSize}`;
      const response = await fetch(url, {
        headers: {
          'Accept-Language': locale,
        },
      });
      const { results } = await response.json();

      setAutoCompleteResults(results);
      if (!autoCompleteOpen) setAutoCompleteOpen(true);
    } else {
      setAutoCompleteOpen(false);
    }
  };

  // if the input is focused and there are already results then we just
  // want to open autocomplete instead of fetching more results
  const handleAutoCompleteOpen = () => {
    if (searchValue) {
      setAutoCompleteOpen(true);
    }
  };

  // submit the form and redirect to search page
  const handleSubmit = (e) => {
    e.preventDefault();

    const inputEl = e.target[name];

    // matches empty string and whitespace
    if (!inputEl.value.match('^\\s*$')) {
      if (mobileSearchOpen) setMobileSearchOpen(false);

      const url = `${urls.search}?${name}=${inputEl.value}`;
      router.push(url);

      inputEl.blur();
    }
  };

  // get url to product for autocomplete
  const getProductUrl = (result) => {
    return `${urls.product}${result.slug}/`;
  };

  // get product label for autocomplete
  const getProductLabel = (result) => {
    return <span className="ac-result-copy">{result.name}</span>;
  };

  // clear search input when clear button is pressed
  const clearSearchForm = () => {
    desktopInputEl?.current?.focus();
    mobileInputEl?.current?.focus();
    setSearchValue('');
    setAutoCompleteOpen(false);
  };

  // arrow in autocomplete dropdown
  const acArrow = (
    <span className="ac-result-arrow-bg">
      <svg className="ac-result-arrow" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="m3.414 2 8.607 8.607-1.414 1.414L2 3.414V11H0V0h11v2H3.414Z" fill="#94919E" />
      </svg>
    </span>
  );

  // magnifying glass in autocomplete dropdown
  const acMagnify = (
    <svg className="ac-result-magnify" viewBox="0 0 14 14" width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="m10.856 9.928 2.81 2.81-.928.929-2.81-2.811A5.91 5.91 0 0 1 .334 6.24 5.91 5.91 0 0 1 6.24.333a5.91 5.91 0 0 1 4.615 9.595ZM9.539 9.44a4.593 4.593 0 0 0-3.298-7.794A4.593 4.593 0 0 0 1.646 6.24a4.593 4.593 0 0 0 7.795 3.298l.098-.098Z" fill="#333040" />
    </svg>
  );

  // btn to clear search input
  const searchClearBtn = (
    <button
      aria-label={ariaButtonLabelClear}
      className={classnames('search-clear-btn', { visible: searchValue })}
      data-content-name="header"
      data-content-piece="search clear"
      onClick={clearSearchForm}
      type="button"
    >
      <svg viewBox="0 0 20 20" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10Zm0-2a8 8 0 1 0 0-16.001A8 8 0 0 0 10 18Zm0-9.414 2.828-2.829 1.415 1.415L11.414 10l2.829 2.828-1.415 1.415L10 11.414l-2.828 2.829-1.415-1.415L8.586 10 5.757 7.172l1.415-1.415L10 8.586Z" fill="#8071BA" />
      </svg>
    </button>
  );

  // the autocomplete dropdown
  const autoComplete = (
    <div className="autocomplete">
      {!!autoCompleteResults?.length && (
        <ul>
          {autoCompleteResults.map((result) => {
            return (
              <li
                className="ac-result"
                key={result.slug}
              >
                <button
                  className="ac-anchor"
                  onClick={() => {
                    // track.event({
                    //   category: 'enhanced search',
                    //   action: 'clicked',
                    //   details: result.webDesc,
                    //   searchTerm: searchValue,
                    // });

                    router.push(getProductUrl(result));
                  }}
                  type="button"
                >
                  <div className="ac-result-image-wrapper">
                    {result?.thumbnail && (
                      <Image
                        alt={result.name}
                        className="ac-result-image"
                        height="68"
                        src={result.thumbnail}
                        width="120"
                      />
                    )}
                  </div>
                  <div className="ac-result-copy-wrapper">
                    <Typography addClasses="ac-anchor-hover" variant="body-bold">
                      {getProductLabel(result)}
                      {acArrow}
                    </Typography>
                  </div>
                </button>
              </li>
            );
          })}
        </ul>
      )}
      <div className="ac-search-btn-wrapper">
        <button className="ac-search-btn" type="submit">
          {acMagnify}
          {[`${autoCompleteCtaLabel} `, <strong key={searchValue}>{searchValue}</strong>]}
        </button>
      </div>
    </div>
  );

  // mobile markup
  const mobile = (
    <div className="search-wrapper" key="search-wrapper-sm">
      <button
        aria-label={ariaButtonLabel}
        onClick={handleOpenMobileSearch}
        type="button"
        data-content-name="header"
        data-content-piece="mobile search"
      >
        <Search />
      </button>

      <div
        className={classnames('mobile-search-input-wrapper', { visible: mobileSearchOpen })}
        ref={mobileWrapper}
      >
        <form className="mobile-search-form" onSubmit={handleSubmit} noValidate autoComplete="off">
          <div className="mobile-search-input-inner">
            <button
              aria-label={ariaButtonLabelClose}
              className="mobile-search-close-btn"
              onClick={handleCloseMobileSearch}
              type="button"
            >
              <svg viewBox="0 0 11 20" width="11" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.212 10 11 17.26l-1.94 2.073L.334 10 9.061.667 11 2.74 4.212 10Z" fill="#C4E2D9" />
              </svg>
            </button>
            <input
              aria-label={ariaLabel}
              className="mobile-search-input"
              name={name}
              onChange={handleAutoComplete}
              onFocus={handleAutoCompleteOpen}
              placeholder={placeHolder}
              ref={mobileInputEl}
              type="search"
              value={searchValue}
            />
            {searchClearBtn}
            <button aria-label={ariaButtonLabel} className="search-submit-btn" type="submit">
              <Search hasBackground={hasButtonBg} />
            </button>
          </div>
          {autoCompleteOpen && autoComplete}
        </form>
      </div>

      <Backdrop className="mobile-search-wrapper" open={mobileSearchOpen} onClick={handleCloseMobileSearch} />
    </div>
  );

  // desktop markup
  const desktop = (
    <div className="search-wrapper-lg" key="search-wrapper-lg" ref={desktopWrapper}>
      <form className="search-form" onSubmit={handleSubmit} noValidate autoComplete="off">
        <TextField
          className={
            classnames('text-input text-input-medium search-input', { 'keyboard-active': isKeydown })
          }
          disabled={disabled}
          fullWidth={fullWidth}
          id={id}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchClearBtn}
                <button aria-label={ariaButtonLabel} className="search-submit-btn" type="submit">
                  <Search hasBackground={hasButtonBg} />
                </button>
              </InputAdornment>
            ),
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            'aria-label': ariaLabel,
            maxLength: 255,
          }}
          inputRef={desktopInputEl}
          name={name}
          onChange={handleAutoComplete}
          onFocus={handleAutoCompleteOpen}
          placeholder={placeHolder}
          required={required}
          type="search"
          value={searchValue}
          variant="outlined"
        />
        {autoCompleteOpen && autoComplete}
      </form>
    </div>
  );

  return (
    <div className="search-container">
      <RouteChangeListener fn={closeAutoCompleteResults} />
      {type === 'desktop' && desktop}
      {type === 'mobile' && mobile}
    </div>
  );
};

SearchInput.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  ariaButtonLabel: PropTypes.string.isRequired,
  ariaButtonLabelClear: PropTypes.string.isRequired,
  ariaButtonLabelClose: PropTypes.string.isRequired,
  autoCompleteCtaLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hasButtonBg: PropTypes.bool,
  id: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  name: PropTypes.string,
  placeHolder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.oneOf([
    'desktop',
    'mobile',
  ]).isRequired,
};

export default SearchInput;
