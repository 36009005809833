import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const ChevronLeft = ({ height = 24, width = 24, className = '' }) => {
  return (
    <div
      style={{
        width,
        height,
      }}
      className={classnames('svg', 'svg-chevron-left', className)}
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" style={{ display: 'block' }}>
        <path fill="currentColor" d="M10.828 12.88l4.95 4.95-1.414 1.414L8 12.88l6.364-6.364 1.414 1.414-4.95 4.95z" />
      </svg>
    </div>
  );
};

ChevronLeft.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
};

export default ChevronLeft;
