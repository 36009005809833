import React from 'react';
import PropTypes from 'prop-types';

const PinIcon = ({ height = 16, width = 16 }) => {
  return (
    <div
      style={{
        width,
        height,
      }}
      className="svg svg-pin-icon"
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="m8 13.425 3.3-3.342a4.743 4.743 0 0 0 1.277-2.42 4.78 4.78 0 0 0-.266-2.73 4.713 4.713 0 0 0-1.718-2.12 4.626 4.626 0 0 0-5.186 0 4.713 4.713 0 0 0-1.718 2.12 4.78 4.78 0 0 0-.266 2.73c.18.917.624 1.76 1.277 2.42L8 13.424zm0 1.908-4.243-4.295a6.099 6.099 0 0 1-1.642-3.111 6.146 6.146 0 0 1 .342-3.51 6.06 6.06 0 0 1 2.21-2.726A5.947 5.947 0 0 1 8 .667c1.187 0 2.347.356 3.333 1.024a6.06 6.06 0 0 1 2.21 2.726 6.146 6.146 0 0 1 .342 3.51 6.099 6.099 0 0 1-1.642 3.11L8 15.334zm0-7.241c.354 0 .693-.142.943-.396a1.359 1.359 0 0 0 0-1.909 1.325 1.325 0 0 0-1.886 0 1.359 1.359 0 0 0 0 1.91c.25.253.59.395.943.395zm0 1.35a2.65 2.65 0 0 1-1.886-.79 2.717 2.717 0 0 1-.78-1.91c0-.716.28-1.403.78-1.91A2.65 2.65 0 0 1 8 4.043a2.65 2.65 0 0 1 1.886.79c.5.507.78 1.194.78 1.91s-.28 1.403-.78 1.91A2.65 2.65 0 0 1 8 9.441z" fill="#3F385A" />
      </svg>
    </div>
  );
};

PinIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default PinIcon;
