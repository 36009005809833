'use client';

/* eslint-disable react-hooks/exhaustive-deps */

import { createContext, useContext, useEffect, useState, useMemo } from 'react';
import { useParams } from 'next/navigation';
import initAnalytics from '../analytics/initAnalytics';
import getTemplate from '../analytics/templates';

const DataContext = createContext();

export function useDataContext() {
  return useContext(DataContext);
}

export default function DataProvider({
  children,
  host,
  kiosk,
  locale,
  location,
  pathname,
  preventTracking,
  wwwHeader,
}) {
  const [categories, setCategories] = useState([]);
  const [locationId, setLocationId] = useState('');
  const [siteData, setsiteData] = useState({});

  const params = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const baseUrl = window.location.origin;
      const url = `${baseUrl}/api/v1/cookie/site/?host=${host}&locale=${locale}&location=${location}`;

      try {
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`DataProvider API request failed with status ${response.status}. URL: ${url}`);
        }

        const data = await response.json();

        setCategories(data.categories);
        setLocationId(data.location.id);
        setsiteData(data);
      } catch (error) {
        // TODO: log error to newrelic
        // eslint-disable-next-line no-console
        console.log(error);
      }
    };

    fetchData();
  }, [host, locale, location]);

  useEffect(() => {
    if (siteData && Object.keys(siteData).length) {
      // * Commented out for now while Beely troubleshoots the multiple async events issue
      // const gtmClick = (e) => {
      //   if (e.target.hasAttribute('data-content-piece')) {
      //     const dpTempTrObj = {
      //       event: 'async',
      //       eventInfo: {
      //         category: 'content',
      //         action: 'clicked',
      //         details: `${e.target.dataset.contentName} | ${e.target.dataset.contentPiece}`,
      //         value: 1,
      //       },
      //     };
      //
      //     window._mtm.push(dpTempTrObj); // eslint-disable-line no-underscore-dangle
      //     window.dataLayer.push(dpTempTrObj);
      //   }
      // };

      // * Commented out for now while Beely troubleshoots the multiple async events issue
      // Track click events
      // window.addEventListener('click', gtmClick);

      // regex to remove leading and trailing slashes
      const leadingAndTrailingSlashes = /^\/|\/$/g;

      const splitPathSegments = (str) => {
        // remove leading and trailing slashes and return array of segments
        return str.trim().replace(leadingAndTrailingSlashes, '').split('/');
      };

      const addSlashes = (str) => {
        // remove then add leading and trailing slashes
        return `/${str.trim().replace(leadingAndTrailingSlashes, '')}/`;
      };

      let template = getTemplate(pathname, wwwHeader);
      if (params && typeof params === 'object') {
        const paramKeys = Object.keys(params);
        if (paramKeys.length) {
          const segments = splitPathSegments(pathname);

          // keep segments that aren't params
          // replace segments that are params with the param key
          // example: /product/some-product-slug -> /product/[product]
          // example: /order/1234 -> /order/[orderid]
          const updatedSegments = segments.map((segment, index) => {
            return index >= segments.length - paramKeys.length
              ? `[${paramKeys[index - (segments.length - paramKeys.length)]}]`
              : segment;
          });

          const templateStr = addSlashes(updatedSegments.join('/'));
          if (templateStr.length) {
            template = getTemplate(templateStr, wwwHeader);
          }
        }
      }

      // Initialize analytics on first page load
      // mtm, gtm analytics
      initAnalytics({
        location: {
          storeNumber: siteData.location?.number,
          storeName: siteData.location?.name,
          tenantStatus: siteData.tenant_status,
        },
        preventTracking,
        template,
      });
    }
  }, [siteData]);

  return (
    <DataContext.Provider value={useMemo(() => {
      return {
        categories,
        host,
        kiosk,
        locale,
        locationId,
        preventTracking,
        siteData,
        wwwHeader,
      };
    }, [
      categories,
      host,
      kiosk,
      locale,
      locationId,
      preventTracking,
      siteData,
      wwwHeader,
    ])}
    >
      {children}
    </DataContext.Provider>
  );
}
