import React from 'react';
import PropTypes from 'prop-types';

const GlobeIcon = ({ height = 25, width = 25 }) => {
  return (
    <div
      style={{
        width,
        height,
      }}
      className="svg svg-globe-icon"
    >
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24">
        <g clipPath="url(#97uuxxz4ca)">
          <path d="M8 14.667A6.667 6.667 0 1 1 8 1.334a6.667 6.667 0 0 1 0 13.333zM6.473 13.11a11.933 11.933 0 0 1-1.122-4.444H2.708a5.338 5.338 0 0 0 3.765 4.444zm.214-4.444A10.61 10.61 0 0 0 8 13.168a10.603 10.603 0 0 0 1.313-4.501H6.687zm6.605 0h-2.643a11.934 11.934 0 0 1-1.122 4.444 5.339 5.339 0 0 0 3.765-4.444zM2.708 7.333H5.35c.083-1.54.465-3.05 1.122-4.444a5.339 5.339 0 0 0-3.765 4.444zm3.98 0h2.625A10.603 10.603 0 0 0 8 2.832a10.603 10.603 0 0 0-1.313 4.501zM9.526 2.89a11.934 11.934 0 0 1 1.122 4.444h2.643A5.339 5.339 0 0 0 9.527 2.89z" fill="#78757E" />
        </g>
        <defs>
          <clipPath id="97uuxxz4ca">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

GlobeIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default GlobeIcon;
