import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

const Search = ({ hasBackground = false, height = 20, width = 21 }) => {
  const svg = (
    <path d="M16.283 14.391l4.217 4.216L19.107 20l-4.216-4.217a8.822 8.822 0 01-5.53 1.939C4.47 17.722.5 13.752.5 8.86S4.47 0 9.36 0c4.892 0 8.862 3.97 8.862 8.86a8.822 8.822 0 01-1.939 5.531zm-1.975-.73a6.87 6.87 0 001.945-4.8A6.89 6.89 0 009.36 1.969a6.89 6.89 0 00-6.892 6.892 6.89 6.89 0 006.892 6.892 6.87 6.87 0 004.8-1.945l.147-.147z" fill="currentColor" />
  );

  if (hasBackground) {
    return (
      <div className="search-input-svg-bg">
        <SvgIcon classes={{ root: 'search-input-svg' }}>
          {svg}
        </SvgIcon>
      </div>
    );
  }

  return (
    <SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`} classes={{ root: 'search-input-svg' }}>
      {svg}
    </SvgIcon>
  );
};

Search.propTypes = {
  hasBackground: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Search;
