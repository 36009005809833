/* eslint-disable prefer-promise-reject-errors */
const getGeolocation = (t) => {
  return new Promise((resolve, reject) => {
    const showLocation = (position) => {
      // returns position.coords.latitude and position.coords.longitude
      resolve(position);
    };

    const geoErrorHandler = (error) => {
      // Replace with translations
      if (error.code === 1) {
        reject({ geoError: t('search.errors.geolocateErrorCode1') });
      } if (error.code === 2) {
        reject({ geoError: t('search.errors.geolocateErrorCode2') });
      } if (error.code === 3) {
        reject({ geoError: t('search.errors.geolocateErrorCode3') });
      } else {
        reject({ geoError: t('search.errors.geolocateErrorDefault') });
      }
    };

    if (navigator.geolocation) {
      const options = { timeout: 60000 };
      try {
        navigator.geolocation.getCurrentPosition(showLocation, geoErrorHandler, options);
      } catch (error) {
        reject({ geoError: t('errors.geolocateErrorDefault') });
      }
    } else {
      reject({ geoError: t('errors.geolocateErrorDefault') });
    }
  });
};

export default getGeolocation;
